import { mapState } from 'vuex';

import { genGUID, generateXHRError } from '@helpers';

import { serviceInterfaces } from '../../_models';

export default {
  name: 'AccountCreator',
  data() {
    return {
      showPassword: false,
      accountFullName: '',
      accountName: null,
      accountApiKey: null,
      accountType: null,
      bindingInterface: null,
      bindingUrl: null,
      bindingUrlAsync: null,
      bindingUrlTracking: null,
      bindingUrlComplaint: null,
      bindingUrlReturns: null,
      bindingUserName: null,
      bindingPassword: null,
      serviceInterfaces,
    };
  },
  computed: {
    ...mapState('enums', {
      accountTypes(state) {
        const { accountTypes = [] } = state.enums;
        return accountTypes.map(({ name: text, value }) => ({ text, value }));
      },
    }),
    isInvalidForm() {
      const validationItems = [
        'accountName', 'accountFullName', 'accountApiKey', 'accountType',
        'bindingInterface', 'bindingUrl',
      ];
      return validationItems.some(i => this[i] === null || !`${this[i]}`);
    },
  },
  methods: {
    genGUID() {
      this.accountApiKey = genGUID();
    },
    showCreatePrompt() {
      if (this.isInvalidForm) {
        return;
      }
      this.$VBlackerTheme.alert.warning({
        text: 'Create service account?',
      }, this.createServiceAccount);
    },
    async createServiceAccount() {
      this.$VBlackerTheme.openLoadingSpinner();
      try {
        await this.$http.post('accounts', {
          accountFullName: this.accountFullName,
          accountName: this.accountName,
          accountApiKey: this.accountApiKey,
          accountType: this.accountType,
          bindingInterface: this.bindingInterface,
          bindingUrl: this.bindingUrl,
          bindingUrlAsync: this.bindingUrlAsync,
          bindingUrlTracking: this.bindingUrlTracking,
          bindingUrlComplaint: this.bindingUrlComplaint,
          bindingUrlReturns: this.bindingUrlReturns,
          bindingUserName: this.bindingUserName,
          bindingPassword: this.bindingPassword,
        });
        this.$VBlackerTheme.notification.success('Service account successfully created');
        this.$router.push({
          name: 'accounts',
          query: { pageNum: '1' },
        }, null, () => {});
      } catch (e) {
        this.$VBlackerTheme.notification.error(generateXHRError(e));
      } finally {
        this.$VBlackerTheme.closeLoadingSpinner();
      }
    },
  },
};
